<template>
  <div>
    <v-row>
      <v-col cols="4">
        <v-layout align-end fill-height>
          <v-btn @click="goTo('newClient')">
            {{ $t("Add customer") }}
            <v-icon right> mdi-plus </v-icon>
          </v-btn>
        </v-layout>
      </v-col>
      <v-spacer></v-spacer>

      <v-col cols="4">
        <v-text-field
          v-model="search"
          single-line
          append-icon="mdi-magnify"
          :label="$t('Search')"
          hide-details
          clearable
        >
          <v-icon slot="append"> mdi-magnify</v-icon></v-text-field
        >
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="clients"
      :items-per-page.sync="itemsPerPage"
      :options.sync="options"
      class="elevation-1 ma-3 pa-4"
      hide-default-footer
      :no-data-text="$t('No data available')"
    >
      <template v-slot:[`item.action`]="{ item, index }">
        <v-btn
          @click="goToDetail('clientDetails', index, item)"
          elevation="2"
          fab
          x-small
        >
          <v-icon>mdi-eye-arrow-right</v-icon>
        </v-btn>
      </template>
      <template v-slot:footer>
        <v-row class="mx-2 py-2" align="center" justify="center">
          <v-spacer></v-spacer>

          <span class="mr-4 grey--text">
            <small
              >{{ $t("Page") }} {{ getPage }} {{ $t("out of") }}
              {{ getAmountOfPages }}</small
            >
          </span>
          <v-btn
            :disabled="hasPrevPage"
            fab
            x-small
            class="mr-1"
            @click="formerPage()"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn
            :disabled="hasNextPage"
            fab
            x-small
            class="ml-1"
            @click="nextPage()"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-row>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import request from "../../request";
export default {
  data() {
    return {
      clients: [],
      search: "",
      page: 1,
      count: 10,
      start: 0,
      amountOfPages: 1,
      options: {},
      itemsPerPage: 10,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("Clientcode"),
          align: "start",
          sortable: true,
          value: "Code",
        },
        {
          text: this.$t("Name"),
          sortable: false,
          value: "Name",
        },
        {
          text: this.$t("Country"),
          sortable: false,
          value: "Country",
        },
        {
          text: this.$t("Language"),
          sortable: false,
          value: "Language",
        },
        {
          text: this.$t("Telephone"),
          sortable: false,
          value: "Phone",
        },
        { text: this.$t("Details"), value: "action", sortable: false },
      ];
    },
    getPage: {
      get() {
        return this.page;
      },
      set(newItem) {
        this.page = newItem;
      },
    },
    hasPrevPage() {
      if (this.getPage == 1) {
        return true;
      }
      return false;
    },
    hasNextPage() {
      if (this.getPage < this.amountOfPages) {
        return false;
      }
      return true;
    },
    getAmountOfPages: {
      get() {
        if (this.amountOfPages == 0) {
          return 1;
        }
        return this.amountOfPages;
      },
      set(newItem) {
        this.amountOfPages = newItem;
      },
    },
  },
  watch: {
    search: function (params) {
      params ? params : (params = "");
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.resetPaging();
        this.getCustomers({
          start: this.start,
          count: this.count,
          search: params,
          sort: this.options.sortBy.length > 0 ? this.options.sortBy[0] : "",
          sortDescending:
            this.options.sortDesc.length > 0 ? this.options.sortDesc[0] : false,
        });
      }, 800);
    },
    options: {
      handler() {
        this.getCustomers({
          start: this.start,
          count: this.count,
          search: this.search ? this.search : "",
          sort: this.options.sortBy.length > 0 ? this.options.sortBy[0] : "",
          sortDescending:
            this.options.sortDesc.length > 0 ? this.options.sortDesc[0] : false,
        });
      },
      deep: true,
    },
  },
  created() {
    this.getCustomers({
      start: 0,
      count: this.itemsPerPage,
      search: this.search ? this.search : "",
      sort: "",
      sortDescending: false,
    });
  },
  methods: {
    resetPaging() {
      this.page = 1;
      this.start = 0;
      this.count = 10;
    },
    goTo(route) {
      this.$router.push({ name: route });
      this.$router.go(1);
    },
    goToDetail(route, index, item) {
      this.$router.push({
        name: route,
        params: { id: this.$route.params.id, clientId: item.Id },
      });
      this.$router.go(1);
    },
    getCustomers(items) {
      request.get(
        "/api/kdesign/customers?start=" +
          items.start +
          "&count=" +
          items.count +
          "&sort=" +
          items.sort +
          "&sortDescending=" +
          items.sortDescending +
          "&search=" +
          items.search +
          "&getAll=true",
        null,
        (res) => {
          if (res.success) {
            this.clients = res;
          }
        }
      );
      this.getCustomersCount(items);
    },
    getCustomersCount(items) {
      request.get(
        "/api/kdesign/customerscount?search=" + items.search + "&getAll=true",
        null,
        (res) => {
          this.amountOfPages = Math.ceil(res.item / this.itemsPerPage);
        }
      );
    },
    nextPage() {
      this.page++;
      this.start = this.start + this.count;
      this.getCustomers({
        start: this.start,
        count: this.count,
        search: this.search ? this.search : "",
        sort: this.options.sortBy.length > 0 ? this.options.sortBy[0] : "",
        sortDescending:
          this.options.sortDesc.length > 0 ? this.options.sortDesc[0] : false,
      });
    },

    formerPage() {
      this.page--;
      this.start = this.start - this.itemsPerPage;
      this.getCustomers({
        start: this.start,
        count: this.count,
        search: this.search ? this.search : "",
        sort: this.options.sortBy.length > 0 ? this.options.sortBy[0] : "",
        sortDescending:
          this.options.sortDesc.length > 0 ? this.options.sortDesc[0] : false,
      });
    },
  },
};
</script>

<style>
</style>